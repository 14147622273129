/* AktivGroteskCorp */
@font-face {
  font-family: 'AktivGroteskCorp';
  src: url('./../fonts/AktivGroteskCorp-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'AktivGroteskCorp';
  src: url('./../fonts/AktivGroteskCorp-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'AktivGroteskCorp';
  src: url('./../fonts/AktivGroteskCorp-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AktivGroteskCorp';
  src: url('./../fonts/AktivGroteskCorp-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'AktivGroteskCorp';
  src: url('./../fonts/AktivGroteskCorp-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AktivGroteskCorp';
  src: url('./../fonts/AktivGroteskCorp-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'AktivGroteskCorp';
  src: url('./../fonts/AktivGroteskCorp-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'AktivGroteskCorp';
  src: url('./../fonts/AktivGroteskCorp-BoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

/* DIN Medium Regular */
@font-face {
  font-family: 'DIN-medium-regular';
  src: url('./../fonts/DIN-Medium-Regular.otf') format('opentype');
}

/* Silka */
@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-ExtraLight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-ExtraLightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-SemiBoldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-Black.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Silka';
  src: url('./../fonts/Silka-BlackItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}
