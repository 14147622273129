html,
body {
  width: 100%;
  height: 100%;
}

body {
  background-image: url('./../img/SAL_lobby_C.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.main-content {
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55);

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  @include media-breakpoint-up(lg) {
    background: rgba(0, 0, 0, 0.4);

    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @include media-breakpoint-up(xxl) {
    background: rgba(0, 0, 0, 0.35);
  }
}

.branding {
  position: absolute;
  top: 0;
  left: 30px;
  width: 30px;
  height: 84px;
  background-image: url('./../img/JWALogo.png');
  background-repeat: no-repeat;
  background-size: 100%;

  @include media-breakpoint-up(lg) {
    width: 26px;
    height: 72px;
    left: 15px;
  }

  @include media-breakpoint-up(xxl) {
    width: 43px;
    height: 118px;
  }
}

.site-title {
  @extend .justify-content-center;

  display: none;
  padding-top: 29px;

  @include media-breakpoint-up(lg) {
    display: flex;
  }

  .site-logo {
    display: block;
    width: 256px;
    height: 30px;
    background-image: url('./../img/jwa-logo.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50% 50%;
  }

  // span {
  //   cursor: default;
  //   font-family: 'DIN-medium-regular';
  //   font-size: 20px;
  //   color: white;
  // }
}

.main {
  display: block;
  margin-top: 100px;

  h1 {
    color: white;
    font-family: 'Silka';
    font-weight: 400;
    margin-bottom: 0;
    font-size: 28px;
    line-height: 34px;

    @include media-breakpoint-up(lg) {
      font-size: 44px;
      line-height: 54px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 60px;
      line-height: 68px;
    }
  }

  p {
    color: white;
    font-family: 'AktivGroteskCorp';
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    margin-top: 18px;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
      line-height: 30px;
      margin-top: 30px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 18px;
      line-height: 30px;
      margin-top: 42px;
    }
  }
}

.footer {
  background-color: white;
  padding: 30px 15px 16px;
  margin-left: -30px;
  margin-right: -30px;

  .row {
    > div {
      padding-left: 30px;
      padding-right: 30px;

      @include media-breakpoint-up(lg) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-left: 0;
    margin-right: 0;
    padding: 36px 62px;
  }

  @include media-breakpoint-up(xxl) {
    padding: 48px 72px;
  }

  .footer-item {
    @include media-breakpoint-up(lg) {
      flex-direction: column;
    }

    .footer-title {
      font-family: 'Silka';
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 10px;
      color: theme-color("color-dark");

      strong {
        font-weight: 600;
      }

      @include media-breakpoint-up(lg) {
        font-weight: 600;
        font-size: 16px;
        line-height: 36px;
        margin-bottom: 0;
      }
    }

    a,
    p {
      font-family: 'Silka';
      font-size: 14px;
      font-weight: 400;
      color: theme-color("color-dark");
      margin-bottom: 0;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}